<template>
  <section class="content-tc">
    <div class="text-center mb-4">
      <h4>SYARAT DAN KETENTUAN GRADANA</h4>
      <h4>BAGI PENYEWA</h4>
    </div>
    <p>
      <a href="https://gradana.co.id/">www.gradana.com</a> <b>(“Situs”)</b> adalah Situs yang
      dikelola oleh PT Gradana Teknoruci Indonesia <b>(“Gradana”)</b> yang untuk selanjutnya disebut
      “Kami”. Syarat dan ketentuan berikut merupakan perjanjian hukum <b>(“PERJANJIAN ”)</b> antara
      Penyewa dengan Gradana yang mengatur penggunaan layanan Gradana.
    </p>
    <p>
      Penyewa harus membaca dan memahami semua persyaratan dan ketentuan dalam Perjanjian ini
      sebelum menggunakan fitur layanan dan/atau menerima konten yang terdapat di dalam Situs.
      Apabila Penyewa menekan tombol “Setuju” pada kolom yang disediakan, maka Penyewa telah
      menerima dan menyetujui seluruh persyaratan dan ketentuan dalam Perjanjian untuk menggunakan
      dan menerima layanan dan akses atas seluruh konten atau fitur layanan yang terdapat dalam
      Situs.
    </p>
    <ol type="A">
      <li><b>PENDAHULUAN</b></li>
      <ol>
        <li>Perjanjian ini diatur dan diinterpretasikan berdasarkan Hukum Republik Indonesia.</li>
        <li>
          Kami dapat mengubah atau memperbarui Perjanjian ini setiap waktu dengan mencantumkan
          Perjanjian yang telah diubah atau diperbarui di dalam Situs dan/atau media lain.
          Perjanjian yang telah diubah dan diperbarui tersebut akan berlaku setelah 3 (tiga) hari
          kalender terhitung sejak tanggal diumumkan di Situs. Penyewa dianggap mengetahui dan
          menyetujui perubahan atau pembaruan atas Perjanjian layanan Situs apabila Penyewa terus
          menggunakan Situs setelah perubahan atau pembaruan tersebut berlaku dan tercantum pada
          Situs. Oleh karenanya Penyewa diharapkan untuk memeriksa halaman ini secara berkala agar
          mengetahui perubahan- perubahan tersebut.
        </li>
        <li>
          Kami juga dapat mengubah, menghapus, menambah atau memperbarui fitur atau fasilitas dalam
          Situs ini setiap waktu tanpa mengumumkan terlebih dahulu mengenai hal tersebut.
        </li>
      </ol>
      <li class="mt-3">DEFINISI</li>
      <ol>
        Kecuali konteksnya menentukan lain, istilah-istilah dalam Syarat dan Ketentuan di bawah ini
        akan memiliki arti sebagai berikut:
        <li><b>Deposit</b> adalah sebagaimana didefinisikan dalam paragraf D.1.</li>
        <li>
          <b>Harga</b> Sewa Tahunan adalah harga sewa unit properti yang dipasarkan oleh Pemilik
          Properti dan / atau agen yang ditunjuk, yang dibayar secara tahunan di muka.
        </li>
        <li>
          <b>Harga Sewa Bulanan</b> adalah harga sewa unit properti yang dibayar oleh Penyewa yang
          dibayar secara bulanan melalui Platform Gradana.
        </li>
        <li>
          <b>Lampiran </b>
          adalah lampiran dari Syarat dan Ketentuan ini dan merupakan bagian yang tidak terpisahkan
          dari Syarat dan Ketentuan ini.
        </li>
        <li>
          <b>Layanan </b> adalah jasa penyediaan uang yang disediakan oleh Perusahaan pada Platform
          Gradana untuk mempertemukan para Pendana dan Penyewa sehingga Penyewai dapat mencicil sewa
          unit properti dari Pemilik Properti dan / atau agen.
        </li>
        <li><b>Pemilik Properti</b> adalah pemilik properti yang telah menjadi rekanan Gradana.</li>
        <li>
          <b>Penyewa</b> adalah setiap Pengguna yang menggunakan Layanan yang bermaksud untuk
          menyewa unit properti dari Pemilik Properti dan / atau agen.
        </li>
        <li>
          <b>Pemberitahuan Pengosongan </b>adalah pemberitahuan yang diinformasikan melalui email,
          telp, surat dan / atau secara tertulis dan disampaikan secara langsung oleh Pemilik
          Properti kepada Penyewa apabila Penyewa gagal untuk melakukan pembayaran lebih dari satu
          bulan dari jadwal pembayaran Harga Sewa Bulanan.
        </li>
        <li>
          <b> Pendana</b> adalah Pengguna yang menggunakan Layanan yang bermaksud untuk memberikan
          pinjaman melalui Platform Gradana.
        </li>
        <li>
          Pengguna adalah setiap orang yang mengunjungi, mengakses dan/atau menggunakan Platform
          Gradana.
        </li>
        <li>
          Perusahaan atau Kami atau Gradana adalah PT Gradana Teknoruci Indonesia suatu perusahaan
          yang menyediakan Platform dalam rangka melaksanakan kegiatan pinjaman secara peer to peer
          (peer to peer lending).
        </li>
        <li>
          Platform Gradana adalah web platform Gradana di www.gradana.com dan / atau
          www.gradana.co.id.
        </li>
        <li>
          Rekening Gradana Pendana adalah sub-rekening Perusahaan yang difungsikan untuk menerima
          Nilai Paket Pendanaan dari masing-masing Pendana.
        </li>
        <li>
          Rekening Gradana Penyewa adalah sub-rekening Perusahaan yang difungsikan untuk menampung
          cicilan sewa dari Penyewa untuk kemudian didistribusikan secara prorata ke masing-masing
          Pendana selama masa cicilan sewa.
        </li>
        <li>
          Perjanjian Sewa Menyewa adalah perjanjian sewa menyewa antara Pemilik Properti, Penyewa
          dan Perusahaan sehubungan dengan penyewaan unit properti serta cara pembayarannya dalam
          rangka penyediaan Layanan yang diberikan oleh Perusahaan.
        </li>
      </ol>
      <li class="mt-3"><b>LATAR BELAKANG KERJASAMA</b></li>
      <ol>
        <li>
          Situs yang Kami kelola adalah Platform Gradana, yang merupakan platform pendanaan peer to
          peer yang mempertemukan Pendana dan Penyewa, dimana Penyewa melalui Platform Gradana dapat
          menyewa rumah / apartemen / ruko dan melakukan pembayaran secara bulanan.
        </li>
        <li>
          Pendana (melalui skema crowdfunding Perusahaan) memberikan fasilitas talangan sewa kepada
          Pemilik Properti dimana selanjutnya Penyewa akan membayar cicilan sewa setiap bulannya
          kepada Pendana melalui Rekening Gradana Penyewa.
        </li>
      </ol>
      <li class="mt-3"><b> MEKANISME KERJASAMA:</b></li>
      <ol>
        <li>
          Penyewa akan menghubungi Kami setelah Penyewa memilih unit yang akan disewa dari Pemilik
          Properti dan memberikan dokumen-dokumen persyaratan yang diminta antara lain:
          <ul>
            <li>fotokopi KTP</li>
            <li>fotokopi NPWP;</li>
            <li>fotokopi kartu Pegawai (ID card) / kartu nama</li>
            <li>fotokopi rekening tabungan 2 bulan terakhir;</li>
            <li>Surat Pernyataan dari Penjamin</li>
            <li>KTP Penjamin</li>
          </ul>
          Membayar administration fee senilai Rp 150.000,- (seratus lima puluh ribu rupiah) dan
          mentransfer deposit senilai 1 bulan sewa ke Rekening Gradana Penyewa <b>(“Deposit”)</b>.
        </li>
        <li>
          Penyewa berkewajiban untuk melengkapi formulir aplikasi dan kuesioner yang diberikan oleh
          Gradana dengan sebenar-benarnya serta melengkapi dokumen-dokumen persyaratan yang diminta,
          membayar administration fee dan mentransfer deposit sesuai ketentuan.
        </li>

        <li>
          Setelah data diterima secara lengkap, Kami akan melakukan verifikasi dan membuatkan
          profile bagi Penyewa untuk ditampilkan di Situs Gradana. Mengingat bahwa masa reservasi
          unit antara pemilik unit properti dan Gradana hanya berlaku selama 14 hari kerja sejak
          pemberitahuan awal terkait maksud Penyewa untuk melakukan penyewaan unit melalui Gradana,
          maka Penyewa diharapkan dapat mengumpulkan data yang diminta secepatnya dan mentransfer
          Deposit paling lambat dalam waktu 5 hari kerja sejak Penyewa mengajukan aplikasi agar
          pihak Gradana dapat melakukan verifikasi dan menampilkan profile Penyewa beserta properti
          yang ingin disewa kepada calon Pendana.
        </li>

        <li>
          Setelah Penyewa memperoleh konfirmasi terkait pendanaan melalui Gradana, Pemilik Properti,
          Penyewa dan Gradana (mewakili Pendana) akan menandatangani Perjanjian Sewa Menyewa yang
          mencantumkan, antara lain:
          <ol type="i">
            <li>Harga Sewa Bulanan, lama sewa dan lokasi sewa;</li>
            <li>denda keterlambatan pembayaran sewa;</li>
            <li>akibat kegagalan pembayaran;</li>
            <li>kewajiban-kewajiban Penyewa lainnya; dan</li>
            <li>
              akibat apabila Penyewa tidak memenuhi kewajiban-kewajiban yang disebutkan di atas.
              Secara garis besar, apabila terjadi wanprestasi, maka kewajban tersebut akan
              diselesaikan dengan menggunakan Deposit dan apabila ada nilai yang tersisa baru
              dikembalikan ke Penyewa. Sedangkan apabila ternyata Deposit tidak cukup untuk
              menyelesaikan semua kewajiban tersebut di atas, maka Penyewa wajib membayar
              kekurangannya kepada Pemilik Properti dan / atau Gradana (mewakili Pendana).
            </li>
          </ol>
          Khusus untuk Penyewa yang berbentuk badan atau perusahaan, diwajibkan untuk menyertakan 12
          (dua belas) giro mundur masing-masing senilai Harga Sewa Bulanan. Pada tanggal jatuh tempo
          pembayaran yaitu tanggal 1 (satu) setiap bulannya, 1 (satu) giro mundur akan dicairkan
          sebagai pembayaran Sewa Bulanan. Untuk menghindari keragu-raguan, penandatanganan
          perjanjian dan penyertaan giro harus dilakukan di hari yang sama.
        </li>
        <li>
          Setelah Perjanjian Sewa Menyewa telah ditandatangani, maka Gradana (mewakili Pendana) akan
          mentransfer nilai Harga Sewa Tahunan dari Rekening Gradana Pendana ke rekening yang
          ditentukan oleh Pemilik Properti dan Pemilik Properti akan mendatangani Tanda Terima
          Gradana & Surat Kesepakatan yang menyatakan bahwa apabila Penyewa mengalami keterlambatan
          pembayaran selama 2 (dua) minggu setelah jadwal angsuran bulanan, maka Pemilik Properti
          bersedia untuk mematikan jalur utilitas ke unit properti dan apabila pembayaran angsuran
          bulanan terlambat selama satu bulan, maka Pemilik Properti dapat melakukan pengusiran
          Penyewa dari unit properti.
        </li>

        <li>
          Pembayaran Harga Sewa Bulanan oleh Penyewa
          <ol>
            <li>
              Penyewa akan membayar Harga Sewa Bulanan ke Rekening Gradana Penyewa, untuk kemudian
              didistribusikan oleh Perusahaan secara prorata ke rekening masing-masing Pendana.
            </li>
            <li>
              Gradana akan melakukan pemotongan biaya administrasi transfer (apabila ada) untuk
              segala pengiriman dan pembayaran kepada masing-masing Pendana.
            </li>
            <li>
              3 (tiga) bulan sebelum masa sewa berakhir, Pemilik Properti dan / atau agen yang
              ditunjuk akan mengkonfirmasi apakah Penyewa akan memperpanjang sewa untuk 1 (satu)
              tahun berikutnya.
            </li>
          </ol>
        </li>
        <li>a</li>
        <li>
          Alur dana dalam skema ini dari pihak yang bersangkutan dapat dilihat dalam Lampiran 1 dari
          Syarat dan Ketentuan ini.
        </li>
      </ol>
      <li class="mt-3">
        <b>HAMBATAN PEMBAYARAN DARI PENYEWA</b>
      </li>
      <ol>
        <li>
          Apabila Penyewa dengan alasan apapun tidak melaksanakan pembayaran sewat tepat waktu, maka
          Penyewa akan dikenakan denda keterlambatan pembayaran sebesar 0.2% (nol koma dua persen)
          untuk setiap hari keterlambatan yang dihitung dari jumlah cicilan yang terlambat dibayar.
          Denda dihitung sejak keterlambatan pembayaran tersebut terjadi sampai dengan pembayaran
          dilakukan, dan wajib dibayar sekaligus lunas bersama-sama dengan cicilan Down Payment oleh
          Penyewa kepada pengembang dalam waktu 7 (tujuh) hari setelah diminta oleh Gradana.
        </li>
        <li>
          Apabila Penyewa tidak melakukan pembayaran sewa :
          <ol type="a">
            <li>
              Gradana berhak untuk menagih tunggakan sewa tersebut dari Penjamin Penyewa sejak hari
              pertama pembayaran terlambat dilakukan;
            </li>
            <li>
              Apabila keterlambatan sewa berlangsung lebih dari 14 (empat belas) hari, Pemilik
              Properti akan mematikan jalur / aliran utiltas ke Unit Properti dan / atau mengunci
              akses masuk ke Unit Properti.
            </li>
            <li>
              Apabila keterlambatan sewa berlangsung lebih dari 30 (tiga puluh) hari, maka:
              <ol type="i">
                <li>uang sewa akan diambil dari Deposit;</li>
                <li>Pemilik akan menyampaikan Pemberitahuan Pengosongan; dan</li>
                <li>Penyewa harus meninggalkan unit properti pada hari ke 31 (tiga puluh satu).</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Pengaturan lebih lanjut terkait hal diatas tercantum dalam Perjanjian Sewa Menyewa
          bersyarat antara Pemilik Unit Properti, Penyewa dan Gradana (mewakili Pendana).
        </li>
      </ol>
      <li class="mt-3"><b>DEPOSIT</b></li>
      <ol>
        <li>
          Nilai deposit yang harus dibayarkan Penyewa adalah senilai 1 (satu) bulan sewa yang akan
          ditransfer ke escrow account Gradana. Adapun 1 (satu) bulan deposit akan digunakan untuk
          menjaga apabila ada keterlambatan pembayaran dari Penyewa.
        </li>
        <li>
          Apabila ternyata dalam waktu 7 (tujuh) hari kerja setelah pendaftaran Penyewa disetujui
          Gradana dan profile Penyewa dipubikasikan di portal, namun tidak memperoleh konfirmasi
          Pendana, maka Deposit tersebut akan dikembalikan kepada Penyewa.
          <br />
          Apabila pada masa akhir sewa tidak ada kerusakan atau tunggakan apapun maka Deposit akan
          dikembalikan kepada Penyewa. Sebaliknya apabila ada kerusakan atau tunggakan lainnya yang
          jumlahnya melebihi deposit yang ada, maka Penyewa wajib untuk membayar kekurangannya
          kepada Pemilik Unit Properti dan / atau Gradana (mewakili Pendana).
        </li>
      </ol>
      <li class="mt-3"><b>INFORMASI PEMINJAM</b></li>
      <ol>
        <li>
          Penyewa diwajibkan memberi data informasi pribadi yang sebenarnya. Selain itu Penyewa juga
          diwajibkan memberi keterangan kontak yang sebenarnya.
        </li>
        <li>
          Kami berhak untuk tidak memproses registrasi Penyewa yang tidak memenuhi persyaratan atau
          yang dicurigai tidak memberikan informasi yang sebenarnya.
        </li>
        <li>
          Kami berhak untuk membatasi atau menghentikan proses penghubungan Pendana dengan Penyewa
          jika kami memiliki alasan untuk mencurigai bahwa Penyewa telah melanggar ketentuan dari
          Perjanjian ini atau peraturan perundang-undangan yang berlaku.
        </li>
        <li>
          Apabila ditemukan bahwa data-data, informasi atau dokumen yang diberikan oleh Penyewa
          adalah palsu atau tidak benar, maka uang deposit yang telah dibayarkan akan hangus dan
          nama Penyewa akan dimasukan di daftar hitam dan tidak dapat melakukan aplikasi kembali ke
          depannya.
        </li>
        <li
          v-observe-visibility="{
            callback: visibilityChanged,
            once: true,
          }"
        >
          Sanksi pidana atas pemalsuan atau pemberian keterangan palsu berlaku bagi Penyewa yang
          memberikan informasi atau data tidak benar atau palsu.
        </li>
      </ol>
    </ol>
  </section>
</template>

<script>
export default {
  data: () => ({
    isEnable: false,
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    visibilityChanged(isVisible, entry) {
      this.isEnable = isVisible;
      this.$emit('clicked', this.isEnable);
    },
  },
};
</script>

<style lang="scss" scoped>
section.content-tc {
  padding: 0 70px;
  max-height: 600px;
  overflow: auto;
  p,
  li {
    text-align: justify;
  }
}
@media only screen and (max-width: 767px) {
  section.content-tc {
    padding: 0 10px;
  }
}
</style>
